import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../styles/styles.scss'

import { Language, persistLanguage, getLanguage } from '../state'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'

interface SiteMetadata {
  siteMetadata: {
    title: string
    description: string
  }
}

interface LanguageQuery {
  edges: {
    node: {
      lang: string
      dataString: string
    }
  }[]
}

type StaticQueryProps = {
  site: SiteMetadata
  languages: LanguageQuery
}

class Index extends React.Component<{ site: SiteMetadata }> {
  shouldComponentUpdate() {
    return false
  }

  render() {
    const { site, children } = this.props
    return (
      <>
        <Helmet
          title={site.siteMetadata.title}
          meta={[
            { name: 'description', content: site.siteMetadata.description },
            { name: 'keywords', content: 'Bigland notifications hiring ats' },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
            },
            { name: 'mobile-web-app-capable', content: 'yes' },
            { name: 'apple-mobile-web-app-capable', content: 'yes' },
            { name: 'theme-color', content: '#FFFFFF' },

            { property: 'og:url', content: 'https://notifications.bigland.co/' },
            {
              property: 'og:title',
              content: 'Bigland | Notifications'
            },
            {
              property: 'og:description',
              content: 'Últimas novidades sobre a Bigland.'
            },
            {
              property: 'og:image',
              content:
                'https://static.bigland.co/wp-content/uploads/2018/11/image-principal-site.png'
            }
          ]}
        />
        <link
          rel="icon"
          sizes="32x32"
          href="https://static.bigland.co/wp-content/uploads/2018/10/cropped-favicon-1-32x32.png"
        />
        {children}
      </>
    )
  }
}

interface Dictionaries {
  [lang: string]: {
    [key: string]: string
  }
}

const IndexLayoutInitialState = () => ({
  language: {
    value: getLanguage(),
    loading: true
  }
})

class IndexProvider extends React.Component<
  StaticQueryProps,
  ReturnType<typeof IndexLayoutInitialState>
> {
  get dictionary() {
    return IndexProvider.dictionaries(this.props.languages)
  }
  static languageCode(code: string) {
    return code.split('-')[0]
  }

  static dictionaries(languages: LanguageQuery = { edges: [] }): Dictionaries {
    return languages.edges.reduce(
      (map, { node: { lang, dataString } }) =>
        Object.assign(map, {
          [IndexProvider.languageCode(lang)]: JSON.parse(dataString)
        }),
      {}
    )
  }

  state = IndexLayoutInitialState()

  public componentDidMount() {
    this.updateData()
  }

  public componentDidUpdate() {
    this.updateData()
  }

  public render() {
    if (this.props.languages) {
      return (
        <Language.Provider
          value={{
            languages: Object.keys(this.dictionary),
            selected: this.state.language.value,
            setLanguage: this.setLanguage,
            translate: this.translate
          }}
        >
          <Index site={this.props.site}>{this.props.children}</Index>
        </Language.Provider>
      )
    }

    return <Index site={this.props.site}>{this.props.children}</Index>
  }

  private translate = (key: string): string => {
    const dictionaries = this.dictionary
    const language = this.state.language.value
    if (!dictionaries) return ''
    const current = dictionaries[language]
    if (!current) return ''
    return current[key] || key
  }

  private update(value: string) {
    this.setState({
      language: { value, loading: Boolean(this.dictionary[value]) }
    })
  }

  private setLanguage = (value: string) => {
    const language = IndexProvider.languageCode(value)
    persistLanguage(language)
    this.update(value)
  }

  private updateData() {
    const { value, loading } = this.state.language
    const next_loading = Boolean(this.dictionary[value])

    if (loading !== next_loading) {
      this.update(value)
    }
  }
}

const darkTheme = require('src/themes/dark.json')
const MuiTheme = createMuiTheme(darkTheme)

class IndexLayout extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={MuiTheme}>
        <StaticQuery
          render={data => (
            <IndexProvider site={data.site} languages={data.languages} {...this.props} />
          )}
          query={graphql`
            {
              site {
                siteMetadata {
                  title
                  description
                }
              }
              languages: allPrismicDictionary {
                edges {
                  node {
                    lang
                    dataString
                  }
                }
              }
            }
          `}
        />
      </MuiThemeProvider>
    )
  }
}

export default IndexLayout
